<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/home">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>
          <span v-if="locale === 'en'">How To Share</span>
          <span v-if="locale === 'pb'">Como compartilhar</span>
        </li>
      </ul>
    </div>
    <div class="main main_page bg-fff">
      <div class="about_one">
        <div class="one">
          <div class="title">
            <span v-if="locale === 'en'">How To Share</span>
            <span v-if="locale === 'pb'">Como compartilhar</span>
          </div>
          <div class="text" v-if="locale === 'en'">
            1. When you have finished learning about our project then I am sure
            you will want to share it with your friends and bring people along
            to do our sacred project. This is one of the tasks you will have to
            do, and it will also bring you an extra commission.
            <br /><br />
            <img src="@/Content/images/guide/step1.png" />
            <br /><br />
            2. First you have to open our app and then click on the share button
            in the top right corner to get your unique invitation code, after
            which you can share it with your partners to let them join us and
            complete this sacred project together.
            <br /><br />
            <img src="@/Content/images/guide/step2.png" />
            <br /><br />
            3. Once you have shared your invitation code with your partner to
            sign up for an account and join our organisation you can go to the
            home page of the app and click on the team button below to view your
            task progress and get your corresponding task commission.
            <br /><br />
            <img src="@/Content/images/guide/step3.png" />
            <br /><br />
            <img src="@/Content/images/guide/step4.png" />
            <br /><br />
          </div>

          <div class="text" v-if="locale === 'pb'">
            <p>
              • Invista nesta plataforma, o modo proxy será ativado automaticamente, copie seu link proxy exclusivo e envie para seus amigos para se cadastrar e recarregar! O agente pode obter a parte da comissão do subordinado (por exemplo: você é membro A da plataforma, recomenda o membro B e o membro B completa a tarefa de comissão, você pode obter 6% da comissão; desde que seu subordinado conclua o volume de transações a cada dia, você vai Há uma renda constante. Quanto maior o volume de negociação, quanto maior o seu rendimento! ★Etapas da operação: Meu→Convide amigos
            </p>
            <br />
            <p>• Convite para bônus de recarga</p>
            <p>
              - Convide amigos para depositar R$200 pela primeira vez; recompensa R$8
            </p>
            <p>
              - Convide amigos para depositar R$500 pela primeira vez; recompensa R$18
            </p>
            <p>
              - Convide amigos para depositar R$1.500 pela primeira vez; recompensa R$58
            </p>
            <p>
              - Convide amigos para depositar R$3.000 pela primeira vez; recompensa R$118
            </p>
            <p>
              - Convide amigos para depositar R$6.000 pela primeira vez; recompensa R$258
            </p>
            <p>
              -Entre em contato com o atendimento ao cliente on-line para receber!
            </p>
            <br />
            <p>
              • Mais bônus de convite: Equipe→Convide os amigos para completar tarefas e obter bônus
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "@/lang";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
export default {
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      sysDataDictionaryWbObj,
    };
  },
};
</script>
<style lang="scss" scoped>
img {
  width: 100%;
}
</style>